<template>
  <div>
    <v-data-table
      :items="clientes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin clientes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:item.ver="{ item }">
        <v-btn small icon @click="verCliente(item.uuid)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} clientes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    clientes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "nombreCliente",
        },
        {
          text: "Estatus",
          value: "status",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Teléfono",
          value: "telefonoMovil",
        },
        {
          text: "Email",
          value: "correo",
        },
        {
          text: "Regimen",
          value: "regimen",
        },
        {
          text: "RFC",
          value: "rfc",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
    };
  },

  methods: {
    verCliente(id) {
      this.$router.push(`/cliente-detalle/${id}`);
    },
  },
};
</script>
