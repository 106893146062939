<template>
  <v-app>
    <Navigation></Navigation>

    <v-main class="pa-5">
      <v-row class="mb-10" dense align="center">
        <v-img
          :max-height="width_log2"
          :max-width="height_log2"
          :src="logo_2"
        ></v-img>
        <h2 class="ml-5"></h2>
        <v-spacer />
        <v-img
          :max-height="height_log1"
          :max-width="width_log1"
          :src="logo_1"
        ></v-img>
      </v-row>

      <v-row >
        <v-col cols="12">
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="#133B7D"
              centered
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                Agenda
                <v-icon>mdi-bookmark</v-icon>
              </v-tab>

              <v-tab href="#tab-2">
                Buscar Cliente
                <v-icon>mdi-account-star</v-icon>
              </v-tab>
              <v-tab href="#tab-3">
                Buscar Lead
                <v-icon>mdi-account-check</v-icon>
              </v-tab>    
              <v-tab href="#tab-4">
                Crear lead
                <v-icon>mdi-account-plus</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-1'"
              >
                <v-card flat>
                  <v-card-text><leads-agenda></leads-agenda> </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-2'"
              >
                <v-card flat>
                  <v-card-text>
                    <buscar-clientes></buscar-clientes>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="3"
                :value="'tab-3'"
              >
                <v-card flat>
                  <v-card-text><buscar-leads></buscar-leads></v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="4"
                :value="'tab-4'"
              >
                <v-card flat>
                  <v-card-text><crear-lead></crear-lead> </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>

      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Nav.vue";
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
import BuscarClientes from "../../../../views/pages/clientes/dashboard/DashboardClientes.vue"
import BuscarLeads from "../../../../views/pages/leads/BuscarLead.vue"
import CrearLead from "./CrearLead.vue"
import LeadsAgenda from "../servicio_inbound/LeadsAgenda.vue"

export default {
  components: {
    Navigation,
    BuscarClientes,
    BuscarLeads,
    CrearLead,
    LeadsAgenda
  },
  data() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      tab: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
    puedeCrear() {
      const rolesValidos = [
        "ADMINCC",
        "MANAGERCC",
        "DIRECTO",
        "ADMIN",
        "ADMINAGENT",
        "MANAGER",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },
  methods: {
    leadCreado() {
      this.showCreacion = false;
      this.bus.$emit("reload");
    },
  },
};
</script>
