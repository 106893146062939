<template>
  <div>
    <v-card outlined>
      <v-card-title>Clientes por Tipo de Status</v-card-title>
      <canvas id="pie-chart" ref="pieChart"></canvas>
    </v-card>
  </div>
</template>

<script>
import Chart from "chart.js";
import Vue from "vue";

const eventBus = new Vue();

export default {
  props: {
    clientes: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      chartData: {
        type: "pie",
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#385083",
                "#20407b",
                "#a74e18",
                "#f37926",
                "#a1a1a1",
                "#f9c302",
                "#589cdb",
                "#67a93b",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            position: "bottom",
          },
          interaction: {
            mode: "index",
            axis: "y",
          },
        },
        plugins: [
          {
            id: "myEventCatcher",
            beforeEvent(chart, args) {
              if (args.type == "click")
                eventBus.$emit(
                  "clicked",
                  chart.getElementAtEvent(args)[0]._model.label
                );
            },
          },
        ],
      },
    };
  },

  watch: {
    clientes() {
      this.loadChart();
    },
  },

  mounted() {
    this.loadChart();
  },

  methods: {
    loadChart() {
      if (!this.clientes.status) return;
      this.setChartData();
      const canva = this.$refs.pieChart;

      this.$nextTick(() => {
        new Chart(canva, this.chartData);
        eventBus.$on("clicked", (selectedItem) => {
          this.$emit("searchUpdated", {
            status: this.getPayload(selectedItem),
          });
        });
      });
    },

    setChartColors() {
      this.chartData.data.labels.forEach(() =>
        this.chartData.data.datasets[0].backgroundColor.push(
          this.$randomColor()
        )
      );
    },

    setChartData() {
      Object.keys(this.clientes.status).forEach((e) => {
        this.chartData.data.labels.push(e);
        this.chartData.data.datasets[0].data.push(this.clientes.status[e]);
      });
    },

    getPayload(selectedItem) {
      const dictionary = {
        Nuevo: 0,
        Activo: 1,
        Inactivo: 2,
        Baja: -1,
      };

      return dictionary[selectedItem];
    },
  },
};
</script>
