<template>
  <div>
    <v-card>
      <v-toolbar dense dark flat color="#133B7D">
        <v-toolbar-title>
          <span>Buscar por</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6" v-if="rol != 'AGENT'">
              <v-select
                v-model="form.agente_id"
                label="Agente"
                :items="agentes"
                item-text="nombreCompleto"
                item-value="id"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.status"
                label="Estatus"
                :items="clienteStatus"
                item-text="title"
                item-value="id"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="form.nombre"
                label="Nombre"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="form.apellidoPaterno"
                label="Apellido Paterno"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="form.apellidoMaterno"
                label="Apellido Materno"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.rfc"
                label="RFC"
                outlined
                dense
                :rules="[$rules.rfcSimple]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.telefono"
                label="Teléfono"
                outlined
                dense
                prepend-inner-icon="mdi-phone"
                maxLength="10"
                counter
                :rules="[$rules.numeric, localRules.longDigit(10)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.correo"
                label="Correo electrónico"
                outlined
                dense
                prepend-inner-icon="mdi-email"
                :rules="[$rules.email]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!isFormValid || !minimoUnCampoLleno"
          :loading="loading"
          @click="updateSearch"
        >
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <span>Buscar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="minimoUnCampoLleno" text @click="clearForm()"
          >Limpiar filtros</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";

const requestConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.agenteAccessToken,
  },
};

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        emision_id: "",
        status: "",
        agente_id: "",
        ejecutivo_operaciones_id: "",
        ramo: "",
        rango_fechas: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        telefono: "",
        correo: "",
      },
      menuFecha: false,

      clienteStatus: [
        {
          id: 0,
          title: "Nuevo",
        },
        {
          id: 1,
          title: "Activo",
        },
        {
          id: 2,
          title: "Inactivo",
        },
        {
          id: -1,
          title: "Baja",
        },
      ],
      agentes: [],
      operadores: [],
      ramos: [],

      isFormValid: true,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
  },

  watch: {
    currentFilters(v) {
      !!v && this.setCurrent();
    },
  },

  mounted() {
    this.getOperadores();
    this.getAgentes();
    this.getRamos();
    this.setCurrent();
  },

  methods: {
    getOperadores() {
      mainAxios.get("/v1/agentes/operador", requestConfig).then(({ data }) => {
        this.operadores = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    getAgentes() {
      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        this.agentes = data
          .filter((e) => e.rol == "AGENT")
          .map((e) => ({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
          }));
      });
    },

    getRamos() {
      mainAxios.get("/v1/ramo/list", requestConfig).then(({ data }) => {
        this.ramos = data;
      });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};
      Object.keys(this.form).forEach((e) => {
        if (!!this.form[e] || this.form[e] === 0) payload[e] = this.form[e];
      });

      this.$emit("searchUpdated", payload);
    },

    setCurrent() {
      this.clearForm();
      this.form = { ...this.form, ...this.currentFilters };
    },

    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
  },
};
</script>
