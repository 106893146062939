<template>
  <v-app>
    <Navigation v-if="actualView!= '/home/servicios/inbound'" />

    <v-main>
      <v-container>
        <v-row align="center">
          <v-col cols="12">
            <div class="d-flex align-center pb-0">
              <h2 class="ml-5">Búsqueda de leads</h2>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$router.push('/leads')" v-if="actualView!= '/home/servicios/inbound'">
                <v-icon small class="mr-2">mdi-arrow-left</v-icon>
                <span>Regresar</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <FiltrosLeads
          @filtrosModificados="realizarBusqueda"
          :loading="loading"
        />
      </v-container>

      <v-container>
        <ResultadosLeads :loading="loading" :leads="leads" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mainAxios } from "../../../mainAxios";

import Navigation from "../../../components/Nav.vue";
import logoSegurify from "@/assets/logo_segurify.png";

import FiltrosLeads from "@/components/leads/FiltrosLeads.vue";
import ResultadosLeads from "@/components/leads/ResultadosLeads.vue";

export default {
  components: {
    Navigation,
    FiltrosLeads,
    ResultadosLeads,
  },

  data() {
    return {
      logoSegurify,
      loading: false,
      leads: [],
      actualView:window.location.pathname,
      total:0
    };
  },

  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },

  methods: {
    realizarBusqueda(nuevosFiltros) {
      this.loading = true;
      this.leads = [];

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .post("/v1/lead-segurify/list/filtros", nuevosFiltros, config)
        .then((response) => {
          this.loading = false;
          if (response.data.origenes[0].error) return;
          this.total = 0
          response.data.origenes.forEach((lead) => {
            this.leads.push({
              id: lead.origenId,
              nombre: `${lead.nombre} ${lead.apellidoPaterno} ${lead.apellidoMaterno}`,
              medio: lead.medio,
              ramo: lead.ramo,
              canal: lead.canal,
              producto: lead.producto,
              telefono: lead.telefono,
              correo: lead.correo,
              uuid: lead.origenUuid,
              uuid_lead_segurify: lead.uuid,
              foo: "bar",
              lead:
                lead.nombre +
                " " +
                lead.apellidopaterno +
                " " +
                lead.apellidomaterno,
              servicio_embebido: lead.servicio_embebido,
              embedded: false,
            });
          });
        });
    },
  },
};
</script>
