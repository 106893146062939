<template>
  <v-row align="center">
    <v-col cols="12" md="6" lg="8">
      <div class="d-flex align-center pb-0">
        <h2 class="ml-5">Clientes</h2>
      </div>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <v-row dense v-if="actualView!= '/home/servicios/inbound'">
        <v-col v-if="puedeCrear" cols="12">
          <v-btn color="primary" block @click="$router.push('/crear-clientes')">
            <v-icon small class="mr-1">mdi-plus-circle</v-icon>
            <span>Crear Cliente</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import logoSegurify from "@/assets/logo_segurify.png";

export default {
  data() {
    return {
      logoSegurify,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      actualView:window.location.pathname
    };
  },

  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },

    puedeCrear() {
      const rolesValidos = ["OPERADOR", "OPERACIONES", "ADMIN"];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },
};
</script>
